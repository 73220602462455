import React, {Component} from 'react';
// import {Redirect} from 'react-router-dom';
import {Link} from 'react-router-dom';
import background from '../assets/images/loader-bg.jpg';
import loginScreen from '../assets/images/login-screen.jpg';
import site_logo from '../assets/images/white-black-logo.png';
import loader from '../assets/images/770.gif';
import icon from '../assets/images/favicon.png';
import icon2 from '../assets/images/dashboard-icon.png';
import icon3 from '../assets/images/contact-us.png';
import icon4 from '../assets/images/quotion-icon.png';
import icon5 from '../assets/images/history-icon.png';
import icon6 from '../assets/images/sign-in.png';
import icon7 from '../assets/images/user_registration.png';
import {Redirect} from 'react-router-dom';
import $ from 'jquery';
import {api_url} from '../Config';
import swal from 'sweetalert';
import {english} from '../component/English';
import {french} from '../component/French';
import CommonHeader from '../component/CommonHeader';

import slider25852 from '../assets/images/slider-25852.webp';
import banner1258 from '../assets/images/banner1258-1200x0.jpg';
import slidercode from '../assets/images/slider-code.gif';
import slider10587 from '../assets/images/slider-10587.webp';

class Home extends Component {
  constructor(){
    super();
    this.state = {
        redirectToReferrer: false,
        base_url:api_url.base_url,
        loading:false,
        order_id:'',
        orderData:[],
        historyData:[],
        idErr:'',
        track_no:'',
        site_logo:'',
        company_name:'',
        english:english,
        french:french,
    };
    localStorage.setItem('lang',JSON.stringify(this.state.english));
    localStorage.setItem('lang_name','english');

    this.onChange = this.onChange.bind(this);
    this.getShipmentDetails = this.getShipmentDetails.bind(this);
    var api_rul_info = this.state.base_url+'api.php?type=get_site_info';
        fetch(api_rul_info)
        .then(res=>res.json())
        .then(result=>{
            if (result.response==1) {
                this.setState({
                    site_logo:result.site_logo,
                    company_name:result.company_name
                })

            }
        })
}
componentDidMount(){

// $(window).load(function() {
    $(".loader").delay(3400).fadeOut("slow");
  $("#overlayer").delay(3400).fadeOut("slow");
// });
// $(window).load(function() {
    $(".loader").delay(3400).fadeOut("slow");
  $("#overlayer").delay(3400).fadeOut("slow");
// });



setTimeout(() => {
    var string = this.state.company_name;
var array = string.split("");
var timer;

function frameLooper () {
  if (array.length > 0) {
    document.getElementById("text").innerHTML += array.shift();
  } else {
    clearTimeout(timer);
      }
  setTimeout(()=>frameLooper(),150);

}
frameLooper();
  }, 1000);




}
getShipmentDetails(e) {
    e.preventDefault();
    if (this.state.order_id==='') {
      this.setState({
        idErr:'Please enter Track No.'
      })
    }else{

      this.setState({loading:true})

      const sendData = {
          track_no:this.state.order_id
      }
      const trackURL = this.state.base_url+'api.php?type=track_shipments';
      fetch(trackURL,
      {
          method: 'POST',
          headers:
          {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
          },
          body:JSON.stringify(sendData)
      })
      .then((response) => response.json())
      .then(result=>{
       if(result.response===1)
       {
           this.setState({
               orderData:result.result,
               historyData:result.history,
               track_no:result.result[0].order_detail.track_no

           },()=>{
               this.setState({redirectToReferrer:true})
           });
       }else{
         swal('No Record Found.');
       }
       this.setState({loading:false});
      })
      .catch((error) => {
          this.setState({loading:false});
      });
    }
}
onChange(e){
   this.setState({[e.target.name]:e.target.value});
}

render(){
  let lang_name = localStorage.getItem('lang_name');
    if (lang_name ==null) {
       localStorage.setItem('lang',JSON.stringify(this.state.english));
       localStorage.setItem('lang_name','english');
    }
  const lang = JSON.parse(localStorage.getItem("lang")) ? JSON.parse(localStorage.getItem("lang")) : [];
const {loading} = this.state;
    if (this.state.redirectToReferrer) {
      return(<Redirect to={`/track-detail/${this.state.track_no}`}
          />)
    }
    if(localStorage.getItem('customer_id') > 0 )
      {
        return(<Redirect to='dashboard' />)
      }
return(

<React.Fragment>
  <div className="main_body register_main body_img_bg" id="desktop_view">
  <div className="offcanvas-overlay"></div>
  <span className="loader loder_black">
    <img src={background} alt="" />
    <div className="typing_text">
      <img src={site_logo} alt="" />
      <div id="text"></div>
    </div>
  </span>

<div className="login_screen login_loader" id="homejs_page">

    <CommonHeader />


    {loading ?
        <div className="form_loader">
                 <img src={loader} alt="" />
            </div>
        :
         <React.Fragment>

    <div className="shipment_box desktop_layout" id="main_courier_box">
    <form onSubmit={this.getShipmentDetails}>
       <div className="shipment_track_btns">
          <input type="text" placeholder={lang.enter_your_tracking_code} value={this.state.order_id} name="order_id" onChange={this.onChange} />
              <svg className="search_box" viewBox="0 0 24 24"><path d="M11.5 7a2.5 2.5 0 1 1 0 5a2.5 2.5 0 0 1 0-5zm0 1a1.5 1.5 0 1 0 0 3a1.5 1.5 0 0 0 0-3zm-4.7 4.357l4.7 7.73l4.7-7.73a5.5 5.5 0 1 0-9.4 0zm10.254.52L11.5 22.012l-5.554-9.135a6.5 6.5 0 1 1 11.11 0h-.002z" fill="#626262"></path></svg>
              <span>{this.state.idErr}</span>
              <input type="submit" className="login_btn_ shipment_btns mb-0 ml-2 mt-0 mr-2 w-75" value={lang.track_order}  />
        </div>
    </form>

    <div className="slider_box">
      <div id="myCarousel" className="carousel slide" data-ride="carousel">

          <div className="carousel-inner">
            <div className="item active" >
              <img src={slider25852} alt="" />
            </div>
            <div className="item" >
              <img src={banner1258} alt="" />
            </div>
            <div className="item" >
              <img src={slidercode} alt="" />
            </div>
          </div>
          <ol class="carousel-indicators">
            <li data-target="#myCarousel" data-slide-to="0" class="active"></li>
            <li data-target="#myCarousel" data-slide-to="1"></li>
            <li data-target="#myCarousel" data-slide-to="2"></li>
          </ol>
        </div>
  </div>
    
    <div className="row track_order_box">
        
       <div className="col-sm-4 dashboard_icon active_dash_b">
          <a href="https://1shop.com.pk/" target="_blank">
             <div className="inner_boxes_">
                 <div className="shadow_logo">
                 <img src={icon} alt=""/>
                 </div>
            <b>{lang.vendor_login}</b>
             </div>
          </a>
       </div>
       <div className="col-sm-4 dashboard_icon">
          <Link to="/from-me-shipment">
             <div className="inner_boxes_">
                 <div className="shadow_logo">
                 <img src={icon2} alt=""/>
                 </div>
           
            <b> {lang.busingess_dashboard}</b>
             </div>
          </Link>
       </div>
       <div className="col-sm-4 dashboard_icon">
          <Link to="/contact-us">
             <div className="inner_boxes_">
                 <div className="shadow_logo">
                 <img src={icon3} alt=""/>
                 </div>
            
            <b>{lang.contact_us}</b>
             </div>
          </Link>
       </div>
       <div className="col-sm-4 dashboard_icon">
          <Link to="/rates">
             <div className="inner_boxes_">
                 <div className="shadow_logo">
                 <img src={icon4} alt=""/>
                 </div>
            
            <b>{lang.get_quote}</b>
             </div>
          </Link>
       </div>
       <div className="col-sm-4 dashboard_icon">
            {
                localStorage.getItem('consignee_id') ?
                <Link to="/track-listing">
                 <div className="inner_boxes_">
                      <div className="shadow_logo">
                 <img src={icon5} alt=""/>
                 </div>
                  
                  <b>{lang.tracking_history}</b>
                 </div>
              </Link>
            :
               <Link to="/login">
                 <div className="inner_boxes_">
                  <div className="shadow_logo">
                   <img src={icon5} alt=""/>
                   </div>
                  <b>{lang.tracking_history}</b>
                 </div>
              </Link> 
            }
            
       </div>

       <div className="col-sm-4 dashboard_icon active_dash_b">
          <a href="https://1shop.com.pk/login" target="_blank">
             <div className="inner_boxes_">
                 <div className="shadow_logo">
                 <img src={icon6} alt=""/>
                 </div>
            <b>{lang.log_in}</b>
             </div>
          </a>
       </div>

       <div className="col-sm-4 dashboard_icon active_dash_b">
          <a href="https://1shop.com.pk/register" target="_blank">
             <div className="inner_boxes_">
                 <div className="shadow_logo">
                 <img src={icon7} alt=""/>
                 </div>
            <b>{lang.sign_up}</b>
             </div>
          </a>
       </div>


    </div>
      
      {
    //     <div className="login_btm">
    //   <img src={loginScreen} alt=""/>
    // </div>
      }
    </div>
    </React.Fragment>
}
  </div>
</div>
</React.Fragment>
  )
}
}
export default Home;
