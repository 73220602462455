export const french = {
    tracking_history: "Historique de suivi",
    busingess_dashboard: "Tableau de bord d'entreprise",
    enter_your_tracking_code: "Entrez votre code de suivi",
    track_order: "Suivi de commande",
    get_quote: "Obtenez un devis",
    contact_us: "Nous contacter",
    service_type: "Type de service",
    city_area: "Zone de la ville",
    name:"Nom",
    phone: "Téléphone",
    phone_number:"Numéro de téléphone",
    address: "Adresse",
    pickup_address: "Adresse de ramassage",
    previous: "Précédente",
    next: "Suivante",
    delivery_detail: "détail de livraison",
    select: "Sélectionner",
    areas: "Domaines",
    receiver_name: "Nom du destinataire",
    receiver_phone: "Téléphone du récepteur",
    receiver_address:"Adresse du destinataire",
    shipment_details: "Détails de l'envoi",
    item_detail: "Détail de l'article",
    details: "Des détails",
    special_instuction: "instructions spéciales",
    weight: "Poids (kg)",
    no_of_pieces: "Nombre de pièces",
    cod_amount: "Montant COD",
    loading: "Chargement...",
    submit: "Nous faire parvenir",
    home: "Maison",
    booking: "Réservation",
    order: "Commander",
    orders: "ordres",
    pickup_detail:"Détails de ramassage",
    enter_your_name: "Entrez votre nom",
    enter_your_phone: "Numéro de portable",
    your_message: "Votre message ici",
    sign_up: "Sign Up",
    personal_info: "Personal Information",
    company_name: "Company Name",
    person_of_contact: "Person of Contact",
    email: "Email",
    select_city: "Select City",
    cnic: "CNIC",
    cnic_copy: "CNIC Copy",
    bank_information: "Bank Information",
    bank_name: "Bank Name",
    account_title: "Account Title",
    account_number: "Account Number",
    branch_name: "Branch Name",
    branch_code: "Branch Code",
    swift_code: "Swift Code",
    iban: "IBAN",
    password: "Password",
    confirm_password:"Confirm Password",
    dashboard: "Dashboard",
    lets_get_a_quick: "Let's get a quick overview...",
    pending_cod_amount: "Pending COD Amount",
    total_cod: "Total COD Amount",
    lets_start: "Let's get started",
    plz_enter_requested_info: "Please enter the requested information.",
    sign_in: "S'identifier",
    dont_have_accunt: "Vous n'avez pas de compte?",
    taslim_net: "Taslim.net",
    tracking_no: "Numéro de suivi",
    sender_information:"Informations sur l'expéditeur",
    receiver_information: "Informations sur le récepteur",
    order_iformation: "Informations sur la commande",
    date: "Date",
    status:"Statut",
    order_list: "Liste des commandes",
    pickup_city: "Ville de ramassage",
    account_name: "Nom du compte",
    bussiness_name: "Nom de l'entreprise",
    destination: "Destination",
    parcel_weight: "Poids du colis",
    delivery_fees: "Frais de livraison",
    amount: "Montante",
    no_record_found: "Aucun Enregistrement Trouvé",
    pickup_city_select: "Veuillez sélectionner la ville de ramassage",
    rate_transit: "Tarifs & Transit",
    select_service_type: "Veuillez sélectionner le type de service",
    select_distination: "Veuillez sélectionner la ville de destination",
    already_account: "Vous avez déjà un compte?",
    log_in: "Connexion",
    soko_joint: "SokoJoint",
    to_me: "Tome",
    from_me: "De moi",
    rates_transit:"Tarifs et transit",
    pickup_request: "Demande de ramassage",
    track_shipment: "Suivi des envois",
    track_ship_info:"Suivre les informations d'expédition",
    tracking_result: "Suivi des résultats",
    shipper_info: "Informations sur l'expéditeur",
    shipper:"Expéditrice",
    origin: "Origine",
    consigne_info:"Informations sur le destinataire",
    save: "Sauvegarder",
    track_info:"Infos sur la piste",
    tracking_list:"Liste de suivi",
    track_your_pakage:"Suivez votre colis ici",
    track_up_to:"Suivez jusqu'à 10 numéros à la fois. Séparé par une virgule (,)",
    let_us_know:"Faites-nous part de vos commentaires, questions ou problèmes concernant l'application ou les fonctionnalités.",
    logo_img: "Image logo",
    shipping_info: "Informations sur la livraison",
    web_link: "URL de site web",
    account_nature:"Sélectionnez la nature du compte",
    cash_on_deli: "Paiement à la livraison",
    coprate_invoices: "Facturation d'entreprise",
    product_type: "type de produit",
    apparel: "Vêtements",
    auto_pants: "Pantalon automobile",
    expected_avrg: "Expéditions moyennes attendues",
    month: "Mois",
    accessories: "Accessoires",
    gadgets:"Gadgets",
    cosmetics: "Produits de beauté",
    jewellry:"Bijoux",
    stationary:"Stationnaire",
    handicraft: "Artisanat",
    footwear: "Chaussure",
    organic: "Biologique",
    health_pro: "Produits de santé",
    applience_electronics: "Appareils électroménagers ou électroniques",
    home_decore:"Articles de décoration intérieure ou d'intérieur",
    toys: "Jouets",
    fitness: "Articles de fitness",
    market_place: "MarketPlace",
    document:"Document",
    letter: "Des lettres",
    other:"Autres",
    order_src: "Source de la commande:",
    dream_window:"Windows de rêve",
    boumaref:"Boumaref",
    tracking: "Suivie",
    scan_pickup:"Ramassage de numérisation",
    scan_delivery: "Livraison de numérisation",
    search_here : "Search Here",
    kg:"Kg",
    vendor_login:"Vendor Login"
}
